
import axios from "axios";

const userId = localStorage.getItem("device")
console.log(userId)
const authAxios = axios.create({
  // baseURL: "http://localhost:4000",
  baseURL: 'https://quick-survey-server.quicksurveys.io/',
  headers: {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("pwa_token"))}`,
    "X-Request-COde": userId
  },
});

authAxios.interceptors.response.use(
  (config) => {
    return config;
  },
  (error) => {

    let {
      response: {
        data: { status,message },
      },
    } = error;
    // unauthrised user
    if (status === 401) {
    window.location.href = '/block'
      localStorage.clear();
   
    }
    if(message === "Sessoion Expired"){
      window.alert("Your Session Expired")
      localStorage.clear()
      window.location.href = '/'
    }
    return Promise.reject(error);
  }
);

export default authAxios;

